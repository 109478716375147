import React from 'react'
import "../assets/css/loader.css"
function Loader() {
  return (
    <>
  <div className="spinner"></div>

    </>
  )
}

export default Loader